import React from 'react';
import { CustomTheme } from '~/@types/styled-components';
import { rotate } from '~/components/animations';
import Base from '~/components/layout/Base';
import styled, { css } from 'styled-components';
import SpinnerCaption from './SpinnerCaption';

export interface SpinnerProps {
  size?: keyof CustomTheme['spinnerSize'];
  variant?: keyof CustomTheme['colors'];
  isAbsolute?: boolean;
}

const Spinner = styled(Base).attrs<SpinnerProps>(({ children }) => ({
  children: children ? <SpinnerCaption>{children}</SpinnerCaption> : null,
}))<SpinnerProps>`
  ${({ size = 'base', variant = 'black', isAbsolute = true, theme }) => css`
    position: ${isAbsolute ? 'absolute' : null};
    top: ${isAbsolute ? '50%' : null};
    left: ${isAbsolute ? '50%' : null};
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: ${theme.fontSize.sm}px;
    transform: ${isAbsolute ? 'translate(-50%, -50%)' : null};
    color: ${theme.colors[variant]};

    &::before {
      content: '';
      width: ${theme.spinnerSize[size]}px;
      height: ${theme.spinnerSize[size]}px;
      border: ${theme.spinnerSize[size] / 8}px solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      animation: ${rotate} 1s linear infinite;
    }
  `}
`;

export default Spinner;
