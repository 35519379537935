import { keyframes } from 'styled-components';

const progressY = keyframes`
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
`;

export default progressY;
