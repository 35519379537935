import { BaseSpan } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const ButtonLink = styled(BaseSpan).attrs(({ role = 'button' }) => ({ role }))`
  ${({ theme }) => css`
    color: ${theme.linkColor};

    &:hover {
      color: ${theme.linkHoverColor} !important;
    }
  `}
`;

export default ButtonLink;
