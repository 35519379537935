import { BaseSpan } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const SpinnerCaption = styled(BaseSpan)`
  ${({ theme }) => css`
    margin-top: ${theme.spacers[6]}px;
  `}
`;

export default SpinnerCaption;
