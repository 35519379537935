import React, { useEffect, useRef, useState } from 'react';
import Base, { BaseHOCPropsWithoutRef } from './Base';
import mergeRefs from '~/helpers/mergeRefs';
import styled, { css } from 'styled-components';
import theme from '~/theme/Theme';
import useElementSize from '~/hooks/useElementSize';

type StyledStickyProps = {
  style: React.CSSProperties;
  size: { width: number; height: number };
  isStuck: boolean;
  shouldFitScreen?: boolean;
};

const attrs = ({ style, isStuck }: StyledStickyProps) => ({
  style: { ...style, zIndex: style.zIndex ? style.zIndex : isStuck ? theme.zindex.sticky : undefined },
});

const StyledSticky = styled(Base).attrs<StyledStickyProps>(attrs)<StyledStickyProps>`
  ${({ size, style, isStuck, shouldFitScreen }) => css`
    ${shouldFitScreen
      ? `
        position: sticky;
        scrollbar-width: thin;

        ${
          isStuck &&
          (style.top ?? style.bottom !== undefined) &&
          `
            max-height: ${(style.top ?? style.bottom) ? `calc(100vh - ${style.top ?? style.bottom}px)` : '100vh'};
            overflow-y: auto;
          `
        }

        ${
          isStuck &&
          (style.right ?? style.left !== undefined) &&
          `
            max-width: ${(style.right ?? style.left) ? `calc(100vw - ${style.right ?? style.left}px)` : '100vw'};
            overflow-x: auto;
          `
        }
      `
      : `
        ${
          (style.top ?? style.bottom) !== undefined &&
          `
            @media (min-height: ${Number(style.top ?? style.bottom) + size.height}px) {
              position: sticky;
            }
          `
        }

        ${
          (style.right ?? style.left) !== undefined &&
          `
            @media (min-width: ${Number(style.right ?? style.left) + size.width}px) {
              position: sticky;
            }
          `
        }
    `}
  `}
`;

type StickyType = {
  <C extends React.ElementType = 'div'>(props: StickyProps<C> & { ref?: React.Ref<HTMLElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type StickyInnerProps = {
  style: React.CSSProperties;
  rootId?: string;
  shouldFitScreen?: boolean;
  children: (isStuck?: boolean) => React.ReactNode;
};

type StickyProps<C extends React.ElementType = 'div'> = Omit<BaseHOCPropsWithoutRef<C>, 'children'> & StickyInnerProps;

const Sticky: StickyType = React.forwardRef(
  <C extends React.ElementType = 'div'>({ as, style, rootId, shouldFitScreen, children, ...props }: StickyProps<C>, ref: React.Ref<HTMLElement>) => {
    const innerRef = useRef<HTMLElement | null>(null);
    const innerSize = useElementSize(innerRef);

    const isStuckRef = useRef(false);
    const [isStuck, setIsStuck] = useState<boolean>(false);

    useEffect(() => {
      if (style && innerRef.current) {
        const target = innerRef.current;

        const { top, right, bottom, left } = style;
        const rootTop = top !== undefined ? (Number(top) + 1) * -1 : 0;
        const rootRight = right !== undefined ? (Number(right) + 1) * -1 : 0;
        const rootBottom = bottom !== undefined ? (Number(bottom) + 1) * -1 : 0;
        const rootLeft = left !== undefined ? (Number(left) + 1) * -1 : 0;

        const root = rootId ? document.getElementById(rootId) : null;
        const rootElement = root || document;
        const rootMargin = `${rootTop}px ${rootRight}px ${rootBottom}px ${rootLeft}px`;
        const threshold = 1.0;

        const handleStuck = () => {
          if (target) {
            const rootElement = root || document.documentElement;

            let offset = 0;
            if (top !== undefined) {
              offset = rootElement.scrollTop;
            } else if (right !== undefined) {
              offset = rootElement.scrollWidth - rootElement.offsetWidth - rootElement.scrollLeft;
            } else if (bottom !== undefined) {
              offset = rootElement.scrollHeight - rootElement.offsetHeight - rootElement.scrollTop;
            } else {
              offset = rootElement.scrollLeft;
            }

            const isStuck = offset > 0;

            if ((isStuck && !isStuckRef.current) || (!isStuck && isStuckRef.current)) {
              isStuckRef.current = isStuck;
              setIsStuck(isStuck);
            }
          }
        };

        const handleIntersection: IntersectionObserverCallback = ([e]) => {
          if (target) {
            const { isIntersecting } = e;

            if (!isIntersecting) {
              handleStuck();
              rootElement.addEventListener('scroll', handleStuck);
            } else {
              isStuckRef.current = false;
              setIsStuck(false);
              rootElement.removeEventListener('scroll', handleStuck);
            }
          }
        };

        const observer = new IntersectionObserver(handleIntersection, { root, rootMargin, threshold });

        if (observer) {
          observer.observe(target);

          return () => {
            observer.unobserve(target);
            rootElement.removeEventListener('scroll', handleStuck);
          };
        }
      }
    }, [style, rootId]);

    return (
      <>
        <StyledSticky
          as={as as React.ElementType}
          ref={mergeRefs([ref, innerRef])}
          style={style}
          size={innerSize}
          isStuck={isStuck}
          shouldFitScreen={shouldFitScreen}
          {...props}
        >
          {children(isStuck)}
        </StyledSticky>
      </>
    );
  },
);

Sticky.displayName = 'Sticky';
export default Sticky;
