import { Base } from '~/components/layout';
import { StyledButton } from '~/components/form/Button';
import styled, { css } from 'styled-components';

type StretchedLinkProps = {
  isDisabled?: boolean;
  isFlush?: boolean;
};

const StretchedLink = styled(Base).attrs<StretchedLinkProps>(({ role = 'button' }) => ({ role }))<StretchedLinkProps>`
  ${({ isDisabled = false, isFlush = false, theme }) => css`
    position: static;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
    }

    /*
     * Hover
     */

    ${!isFlush &&
    `
      &:hover {
        color: ${theme.linkHoverColor} !important;
      }
    `}

    /* 
     * Disabled
     */

    ${isDisabled &&
    `
      pointer-events: none;

      &::before,
      &::after {
        display: none;
      }
    `}

    /* 
     * Nested button
     */

    ${StyledButton} {
      z-index: 1;
    }

    /* 
     * Flush
     */

    ${isFlush &&
    `
      &::before {
        display: none !important;
      }
    `}
  `}
`;

export default StretchedLink;
