import { keyframes } from 'styled-components';

const progressInfinite = keyframes`
  0% {
    transform: scaleX(0);
    transform-origin: left top;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left top;
  }
  50.001% {
    transform-origin: right top;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right top;
  }
`;

export default progressInfinite;
