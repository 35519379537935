import { CustomTheme } from '~/@types/styled-components';
import Col from './Col';
import FlexRow from './FlexRow';
import styled, { css } from 'styled-components';
import { BaseProps } from './Base';

type Props = BaseProps & {
  gutter?: keyof CustomTheme['spacers'];
};

type RowProps = Props & {
  mobile?: Props;
  desktop?: Props;
};

const Row = styled(FlexRow)<RowProps>`
  ${({ mobile = {}, desktop = {}, gutter, theme }) => css`
    --row-gutter-x: ${gutter !== undefined ? theme.spacers[gutter] : theme.gridGutterX}px;
    --row-offset-x: calc(var(--row-gutter-x) * -1);
    flex-wrap: wrap;
    margin-right: var(--row-offset-x);
    margin-left: var(--row-offset-x);

    & > ${Col} {
      padding-right: var(--row-gutter-x);
      padding-left: var(--row-gutter-x);
    }

    /*
     * Mobile
     */

    ${mobile.gutter &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --row-offset-x: ${theme.spacers[-mobile.gutter as keyof CustomTheme['spacers']]}px;
        --row-gutter-x: ${theme.spacers[mobile.gutter]}px;
      }
    `}

    /*
     * Desktop
     */

    ${desktop.gutter &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --row-offset-x: ${theme.spacers[-desktop.gutter as keyof CustomTheme['spacers']]}px;
        --row-gutter-x: ${theme.spacers[desktop.gutter]}px;
      }
    `}
  `}
`;

export default Row;
