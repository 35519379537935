import { CustomTheme } from '~/@types/styled-components';
import { getSpacing } from './helpers/getSpacing';
import { rotate } from '~/components/animations';
import React, { CSSProperties } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';

type Props = {
  alignContent?: CSSProperties['alignContent'];
  alignItems?: CSSProperties['alignItems'];
  alignSelf?: CSSProperties['alignSelf'];
  aspectRatio?: CSSProperties['aspectRatio'];
  bgColor?: keyof CustomTheme['colors'];
  bgCover?: string;
  blur?: CSSProperties['borderRadius'];
  border?: number;
  borderBottom?: number;
  borderBottomColor?: keyof CustomTheme['colors'];
  borderBottomLeftRadius?: keyof CustomTheme['borderRadius'];
  borderBottomRadius?: keyof CustomTheme['borderRadius'];
  borderBottomRightRadius?: keyof CustomTheme['borderRadius'];
  borderColor?: keyof CustomTheme['colors'];
  borderLeft?: number;
  borderLeftColor?: keyof CustomTheme['colors'];
  borderRadius?: keyof CustomTheme['borderRadius'];
  borderRight?: number;
  borderRightColor?: keyof CustomTheme['colors'];
  borderStyle?: CSSProperties['borderStyle'];
  borderTop?: number;
  borderTopColor?: keyof CustomTheme['colors'];
  borderTopLeftRadius?: keyof CustomTheme['borderRadius'];
  borderTopRadius?: keyof CustomTheme['borderRadius'];
  borderTopRightRadius?: keyof CustomTheme['borderRadius'];
  borderX?: number;
  borderY?: number;
  bottom?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  boxShadow?: keyof CustomTheme['boxShadow'];
  children?: React.ReactNode;
  color?: keyof CustomTheme['colors'];
  columnGap?: keyof CustomTheme['spacers'] | CSSProperties['columnGap'];
  display?: CSSProperties['display'];
  flex?: CSSProperties['flex'];
  flexBasis?: CSSProperties['flexBasis'];
  flexDirection?: CSSProperties['flexDirection'];
  flexGrow?: CSSProperties['flexGrow'];
  flexShrink?: CSSProperties['flexShrink'];
  flexWrap?: CSSProperties['flexWrap'];
  float?: CSSProperties['float'];
  fontFamily?: CSSProperties['fontFamily'];
  fontSize?: keyof CustomTheme['fontSize']['mobile'];
  fontWeight?: keyof CustomTheme['fontWeight'];
  grid?: CSSProperties['grid'];
  gridAutoColumns?: CSSProperties['gridAutoColumns'];
  gridAutoRows?: CSSProperties['gridAutoRows'];
  gridColumn?: CSSProperties['gridColumn'];
  gridRow?: CSSProperties['gridRow'];
  gridTemplateColumns?: CSSProperties['gridTemplateColumns'];
  gridTemplateRows?: CSSProperties['gridTemplateRows'];
  height?: CSSProperties['height'];
  inset?: CSSProperties['inset'];
  isCentered?: boolean;
  isCenteredX?: boolean;
  isCenteredY?: boolean;
  isRotating?: boolean;
  isSticky?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
  left?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  lineHeight?: CSSProperties['lineHeight'];
  m?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  maxHeight?: CSSProperties['maxHeight'];
  maxWidth?: CSSProperties['maxWidth'];
  mb?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];
  ml?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  mr?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  mt?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  mx?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  my?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  opacity?: CSSProperties['opacity'];
  order?: CSSProperties['order'];
  overflow?: CSSProperties['overflow'];
  overflowX?: CSSProperties['overflowX'];
  overflowY?: CSSProperties['overflowY'];
  p?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  pb?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  pl?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  position?: CSSProperties['position'];
  pr?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  pt?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  px?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  py?: keyof CustomTheme['spacers'] | CSSProperties['padding'];
  right?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  rowGap?: keyof CustomTheme['spacers'] | CSSProperties['rowGap'];
  textAlign?: CSSProperties['textAlign'];
  textDecoration?: CSSProperties['textDecoration'];
  textDecorationStyle?: CSSProperties['textDecorationStyle'];
  textTransform?: CSSProperties['textTransform'];
  textTruncate?: boolean;
  top?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  transform?: CSSProperties['transform'];
  transition?: keyof CustomTheme['transition'];
  visibility?: CSSProperties['visibility'];
  whiteSpace?: CSSProperties['whiteSpace'];
  width?: keyof CustomTheme['spacers'] | CSSProperties['margin'];
  wordBreak?: CSSProperties['wordBreak'];
  wordWrap?: CSSProperties['wordWrap'];
  zindex?: keyof CustomTheme['zindex'];
};

export type BaseProps = { utils?: Props; hover?: Props; parentHover?: Props; mobile?: Props; desktop?: Props };
export type BaseHOCPropsWithoutRef<C extends React.ElementType, P = object> = { as?: C } & React.ComponentPropsWithoutRef<C> & BaseProps & P;

const utilities = (props: Props, theme: CustomTheme) => css`
  /* Position */
  ${props.position && `position: ${props.position} !important;`}
  ${props.inset !== undefined && `inset: ${getSpacing(props.inset)} !important;`};
  ${props.top !== undefined && `top: ${getSpacing(props.top)} !important;`};
  ${props.right !== undefined && `right: ${getSpacing(props.right)} !important;`};
  ${props.bottom !== undefined && `bottom: ${getSpacing(props.bottom)} !important;`};
  ${props.left !== undefined && `left: ${getSpacing(props.left)} !important;`};
  ${props.zindex && `z-index: ${theme.zindex[props.zindex]} !important;`};

  /* Display */
  ${props.display && `display: ${props.display} !important;`};
  ${props.visibility && `visibility: ${props.visibility} !important;`};

  /* Grid */
  ${props.grid && `grid: ${props.grid} !important;`};
  ${props.gridTemplateColumns && `grid-template-columns: ${props.gridTemplateColumns} !important;`};
  ${props.gridTemplateRows && `grid-template-rows: ${props.gridTemplateRows} !important;`};
  ${props.gridColumn && `grid-column: ${props.gridColumn} !important;`};
  ${props.gridRow && `grid-row: ${props.gridRow} !important;`};
  ${props.gridAutoColumns && `grid-auto-columns: ${props.gridAutoColumns} !important;`};
  ${props.gridAutoRows && `grid-auto-rows: ${props.gridAutoRows} !important;`};
  ${props.columnGap !== undefined && `column-gap: ${getSpacing(props.columnGap)} !important;`};
  ${props.rowGap !== undefined && `row-gap: ${getSpacing(props.rowGap)} !important;`};
  ${props.alignContent && `align-content: ${props.alignContent} !important;`};
  ${props.alignItems && `align-items: ${props.alignItems} !important;`};
  ${props.alignSelf && `align-self: ${props.alignSelf} !important;`};

  /* Flex */
  ${props.flex && `flex: ${props.flex} !important;`};
  ${props.flexBasis && `flex-basis: ${props.flexBasis} !important;`};
  ${props.flexDirection && `flex-direction: ${props.flexDirection} !important;`};
  ${props.flexGrow !== undefined && `flex-grow: ${props.flexGrow} !important;`};
  ${props.flexShrink !== undefined && `flex-shrink: ${props.flexShrink} !important;`};
  ${props.flexWrap && `flex-wrap: ${props.flexWrap} !important;`};
  ${props.justifyContent && `justify-content: ${props.justifyContent} !important;`};
  ${props.order !== undefined && `order: ${props.order} !important;`};

  /* Sizing */
  ${props.height !== undefined && `height: ${props.height} !important;`};
  ${props.maxHeight !== undefined && `max-height: ${props.maxHeight} !important;`};
  ${props.maxWidth !== undefined && `max-width: ${props.maxWidth} !important;`};
  ${props.minHeight !== undefined && `min-height: ${props.minHeight} !important;`};
  ${props.minWidth !== undefined && `min-width: ${props.minWidth} !important;`};
  ${props.width !== undefined && `width: ${props.width} !important;`};

  /* Overflow */
  ${props.overflowX && `overflow-x: ${props.overflowX} !important;`};
  ${props.overflowY && `overflow-y: ${props.overflowY} !important;`};
  ${props.overflow && `overflow: ${props.overflow} !important;`};

  /* Margin */
  ${() => {
    const marginTop = (props.m ?? props.my ?? props.mt) as keyof CustomTheme['spacers'];
    return marginTop !== undefined && `margin-top: ${getSpacing(marginTop)} !important;`;
  }};
  ${() => {
    const marginRight = (props.m ?? props.mx ?? props.mr) as keyof CustomTheme['spacers'];
    return marginRight !== undefined && `margin-right: ${getSpacing(marginRight)} !important;`;
  }};
  ${() => {
    const marginBottom = (props.m ?? props.my ?? props.mb) as keyof CustomTheme['spacers'];
    return marginBottom !== undefined && `margin-bottom: ${getSpacing(marginBottom)} !important;`;
  }};
  ${() => {
    const marginLeft = (props.m ?? props.mx ?? props.ml) as keyof CustomTheme['spacers'];
    return marginLeft !== undefined && `margin-left: ${getSpacing(marginLeft)} !important;`;
  }};

  /* Padding */
  ${() => {
    const paddingTop = (props.p ?? props.py ?? props.pt) as keyof CustomTheme['spacers'];
    return paddingTop !== undefined && `padding-top: ${getSpacing(paddingTop)} !important;`;
  }};
  ${() => {
    const paddingRight = (props.p ?? props.px ?? props.pr) as keyof CustomTheme['spacers'];
    return paddingRight !== undefined && `padding-right: ${getSpacing(paddingRight)} !important;`;
  }};
  ${() => {
    const paddingBottom = (props.p ?? props.py ?? props.pb) as keyof CustomTheme['spacers'];
    return paddingBottom !== undefined && `padding-bottom: ${getSpacing(paddingBottom)} !important;`;
  }};
  ${() => {
    const paddingLeft = (props.p ?? props.px ?? props.pl) as keyof CustomTheme['spacers'];
    return paddingLeft !== undefined && `padding-left: ${getSpacing(paddingLeft)} !important;`;
  }};

  /* Border */
  ${props.borderRadius && `border-radius: ${theme.borderRadius[props.borderRadius]}px !important;`};
  ${props.borderTopRadius && `border-top-right-radius: ${theme.borderRadius[props.borderTopRadius]}px !important;`};
  ${props.borderTopRadius && `border-top-left-radius: ${theme.borderRadius[props.borderTopRadius]}px !important;`};
  ${props.borderBottomRadius && `border-bottom-right-radius: ${theme.borderRadius[props.borderBottomRadius]}px !important;`};
  ${props.borderBottomRadius && `border-bottom-left-radius: ${theme.borderRadius[props.borderBottomRadius]}px !important;`};
  ${props.borderTopRightRadius && `border-top-right-radius: ${theme.borderRadius[props.borderTopRightRadius]}px !important;`};
  ${props.borderBottomRightRadius && `border-bottom-right-radius: ${theme.borderRadius[props.borderBottomRightRadius]}px !important;`};
  ${props.borderBottomLeftRadius && `border-bottom-left-radius: ${theme.borderRadius[props.borderBottomLeftRadius]}px !important;`};
  ${props.borderTopLeftRadius && `border-top-left-radius: ${theme.borderRadius[props.borderTopLeftRadius]}px !important;`};
  ${props.border !== undefined && `border: ${theme.borderWidth * props.border}px solid ${theme.borderColor} !important;`};
  ${props.borderX !== undefined && `border-right: ${theme.borderWidth * props.borderX}px solid ${theme.borderColor} !important;`};
  ${props.borderX !== undefined && `border-left: ${theme.borderWidth * props.borderX}px solid ${theme.borderColor} !important;`};
  ${props.borderY !== undefined && `border-top: ${theme.borderWidth * props.borderY}px solid ${theme.borderColor} !important;`};
  ${props.borderY !== undefined && `border-bottom: ${theme.borderWidth * props.borderY}px solid ${theme.borderColor} !important;`};
  ${props.borderTop !== undefined && `border-top: ${theme.borderWidth * props.borderTop}px solid ${theme.borderColor} !important;`};
  ${props.borderRight !== undefined && `border-right: ${theme.borderWidth * props.borderRight}px solid ${theme.borderColor} !important;`};
  ${props.borderBottom !== undefined && `border-bottom: ${theme.borderWidth * props.borderBottom}px solid ${theme.borderColor} !important;`};
  ${props.borderLeft !== undefined && `border-left: ${theme.borderWidth * props.borderLeft}px solid ${theme.borderColor} !important;`};
  ${props.borderStyle && `border-style: ${props.borderStyle} !important;`};
  ${props.borderColor && `border-color: ${theme.colors[props.borderColor]} !important;`};
  ${props.borderTopColor && `border-top-color: ${theme.colors[props.borderTopColor]} !important;`};
  ${props.borderRightColor && `border-right-color: ${theme.colors[props.borderRightColor]} !important;`};
  ${props.borderBottomColor && `border-bottom-color: ${theme.colors[props.borderBottomColor]} !important;`};
  ${props.borderLeftColor && `border-left-color: ${theme.colors[props.borderLeftColor]} !important;`};

  /* Background */
  ${props.bgColor && `background-color: ${theme.colors[props.bgColor]} !important;`};
  ${props.bgCover && `background-image: url(${props.bgCover}) !important;`};
  ${props.bgCover && 'background-size: cover !important;'};
  ${props.bgCover && 'background-position: center center !important;'};

  /* Typography */
  ${props.color && `color: ${theme.colors[props.color]} !important`};
  ${props.lineHeight !== undefined && `line-height: ${props.lineHeight} !important;`};
  ${props.fontFamily && `font-family: ${props.fontFamily} !important;`};
  ${props.fontSize && `font-size: ${theme.fontSize[props.fontSize]}px !important`};
  ${props.fontWeight && `font-weight: ${theme.fontWeight[props.fontWeight]} !important`};
  ${props.wordBreak && `overflow-wrap: ${props.wordBreak} !important;`};
  ${props.textAlign && `text-align: ${props.textAlign} !important;`};
  ${props.textDecoration && `text-decoration: ${props.textDecoration} !important;`};
  ${props.textDecorationStyle && `text-decoration-style: ${props.textDecorationStyle} !important;`};
  ${props.textTransform && `text-transform: ${props.textTransform} !important;`};
  ${props.whiteSpace && `white-space: ${props.whiteSpace} !important;`};
  ${props.wordBreak && `word-break: ${props.wordBreak} !important;`};
  ${props.wordWrap && `word-break: ${props.wordWrap} !important;`};

  /* Other */
  ${props.transition && `transition: ${theme.transition[props.transition]} !important;`};
  ${props.transform && `transform: ${props.transform} !important;`};
  ${props.float && `float: ${props.float} !important;`};
  ${props.opacity && `opacity: ${props.opacity} !important;`};
  ${props.boxShadow && `box-shadow: ${theme.boxShadow[props.boxShadow]} !important;`};

  /* Utilities */
  ${props.blur && `filter: blur(${props.blur}) !important;`};
  ${props.blur && 'pointer-events: none !important !important;'};
  ${props.blur && 'user-select: none !important !important;'};

  ${props.textTruncate && 'overflow: hidden !important;'};
  ${props.textTruncate && ' text-overflow: ellipsis !important;'};
  ${props.textTruncate && 'white-space: nowrap !important;'};

  ${props.isCentered && 'position: absolute !important;'};
  ${(props.isCentered || props.isCenteredY) && 'top: 50% !important;'};
  ${(props.isCentered || props.isCenteredX) && 'left: 50% !important;'};
  ${props.isCentered && 'transform: translate(-50%, -50%) !important;'};
  ${props.isCenteredX && 'transform: translateX(-50%) !important;'};
  ${props.isCenteredY && 'transform: translateY(-50%) !important;'};

  ${props.isRotating && `animation: ${rotate} 2s linear infinite; !important;`};

  ${props.aspectRatio && `aspect-ratio: ${props.aspectRatio} !important;`};
  ${props.aspectRatio && 'object-fit: cover !important;'};
  ${props.aspectRatio && 'object-position: center center !important;'};

  ${props.isSticky && 'position: sticky !important;'};
  ${props.isSticky && `z-index: ${theme.zindex.sticky} !important;`};
`;

const BaseStyles = ({ utils = {}, hover = {}, parentHover = {}, mobile = {}, desktop = {}, theme }: BaseProps & { theme: DefaultTheme }) => css`
  /*
   * Utils
   */

  ${Object.keys(utils).length > 0 &&
  css`
    & {
      ${utilities(utils, theme)}
    }
  `}

  /*
   * Hover
   */

  ${Object.keys(hover).length > 0 &&
  css`
    &:hover {
      ${utilities(hover, theme)}
    }
  `}

  /*
   * Hover (parent)
   */

  ${Object.keys(parentHover).length > 0 &&
  css`
    *:hover > & {
      ${utilities(parentHover, theme)}
    }
  `}

  /*
   * Mobile
   */

  ${utils.fontSize &&
  css`
    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      & {
        font-size: ${theme.fontSize.mobile[utils.fontSize]}px;
      }
    }
  `}

  ${Object.keys(mobile).length > 0 &&
  css`
    @media (max-width: ${theme.breakpoints.desktop - 1}px) {
      & {
        ${utilities(mobile, theme)}
      }
    }
  `}

/*
 * Desktop
 */

${Object.keys(desktop).length > 0 &&
  css`
    @media (min-width: ${theme.breakpoints.desktop}px) {
      & {
        ${utilities(desktop, theme)}
      }
    }
  `}
`;

const Base = styled.div<BaseProps>`
  ${BaseStyles}
`;

export const BaseForm = styled.form<BaseProps>`
  ${BaseStyles}
`;

export const BaseLabel = styled.label<BaseProps>`
  ${BaseStyles}
`;

export const BaseInput = styled.input<BaseProps>`
  ${BaseStyles}
`;

export const BaseButton = styled.button<BaseProps>`
  ${BaseStyles}
`;

export const BaseH1 = styled.h1<BaseProps>`
  ${BaseStyles}
`;

export const BaseSpan = styled.span<BaseProps>`
  ${BaseStyles}
`;

export const BaseHr = styled.hr<BaseProps>`
  ${BaseStyles}
`;

export const BaseMark = styled.mark<BaseProps>`
  ${BaseStyles}
`;

export const BaseSVG = styled.svg<BaseProps>`
  ${BaseStyles}
`;

export const BaseImg = styled.img<BaseProps>`
  ${BaseStyles}
`;

export const BaseUl = styled.ul<BaseProps>`
  ${BaseStyles}
`;

export const BaseLi = styled.li<BaseProps>`
  ${BaseStyles}
`;

export const BaseNav = styled.nav<BaseProps>`
  ${BaseStyles}
`;

export const BaseA = styled.a<BaseProps>`
  ${BaseStyles}
`;

export const BaseMain = styled.main<BaseProps>`
  ${BaseStyles}
`;

export default Base;
