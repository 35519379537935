import { BaseH1 } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

const Heading = styled(BaseH1)`
  ${({ utils = {}, mobile = {}, desktop = {}, theme }) => css`
    ${utils.fontSize &&
    `
      --heading-font-weight: ${theme.headingFontWeight[utils.fontSize]};
      --heading-line-height: ${theme.headingLineHeight[utils.fontSize]};
      --heading-letter-spacing: ${utils.textTransform ? '.08em' : theme.headingLetterSpacing[utils.fontSize]};
    `}

    font-weight: var(--heading-font-weight);
    line-height: var(--heading-line-height);
    letter-spacing: var(--heading-letter-spacing);

    /**
     * Mobile
     */

    ${mobile.fontSize &&
    `
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --heading-line-height: ${theme.headingLineHeight[mobile.fontSize]};
        --heading-letter-spacing: ${theme.headingLetterSpacing[mobile.fontSize]};
      }
    `}

    /**
     * Desktop
     */
    
    ${desktop.fontSize &&
    `
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --heading-line-height: ${theme.headingLineHeight[desktop.fontSize]};
        --heading-letter-spacing: ${theme.headingLetterSpacing[desktop.fontSize]};
      }
    `}
  `}
`;

export default Heading;
