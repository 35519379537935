import { BaseProps } from '../layout/Base';
import { useState } from 'react';
import Text from './Text';

type TextCollapsibleType = BaseProps & {
  maxWords: number;
  children: string;
};

function trimNonLettersFromEnd(inputString: string) {
  // Use a regular expression to match non-letter characters at the end of the string
  const regex = /[^\p{L}]$/u;

  // Repeat the trim operation until no non-letter characters are found at the end
  while (regex.test(inputString)) {
    inputString = inputString.slice(0, -1);
  }

  return inputString;
}

export default function TextCollapsible({ maxWords, children, ...props }: TextCollapsibleType) {
  const [isExpanded, setIsExpanded] = useState(false);
  const wordsLength = children.split(' ').length;

  if (wordsLength <= maxWords) {
    return <Text {...props}>{children}</Text>;
  }

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    setIsExpanded((boolean) => !boolean);
  }

  return (
    <Text {...props}>
      {isExpanded ? children : trimNonLettersFromEnd(children.split(' ').slice(0, maxWords).join(' ')) + '...'}{' '}
      <Text as="span" utils={{ color: 'primary' }} onClick={handleClick} role="button">
        {isExpanded ? 'Read less' : 'Read more'}
      </Text>
    </Text>
  );
}
