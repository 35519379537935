import React from 'react';
import Text from './Text';

type CappedListContentProps = {
  list: string[];
  listLength: number;
  maxLength: number;
};

type CappedListProps = {
  list: string[];
  maxLength: number;
  mobile?: { maxLength?: number };
};

function CappedListContent({ list, listLength, maxLength }: CappedListContentProps) {
  if (listLength <= maxLength) {
    return list.join(', ');
  }

  return (
    <>
      {list.slice(0, maxLength).join(', ')} and{' '}
      <Text
        as="span"
        utils={{ textDecoration: 'underline' }}
        style={{ position: 'relative', zIndex: 1 }}
        data-tooltip-id="GlobalTooltip"
        data-tooltip-content={list.slice(maxLength, listLength).join(', ')}
      >
        {listLength - maxLength} others
      </Text>
    </>
  );
}

export default function CappedList({ list, maxLength, mobile = {} }: CappedListProps) {
  const listLength = list.length;

  if (listLength === 0) {
    return '--';
  }

  if (!mobile.maxLength) {
    return <CappedListContent list={list} listLength={listLength} maxLength={maxLength} />;
  }

  return (
    <>
      <Text mobile={{ display: 'none' }}>
        <CappedListContent list={list} listLength={listLength} maxLength={maxLength} />
      </Text>
      <Text desktop={{ display: 'none' }}>
        <CappedListContent list={list} listLength={listLength} maxLength={mobile.maxLength} />
      </Text>
    </>
  );
}
