import { keyframes } from 'styled-components';

const dropdownMenuIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
`;

export default dropdownMenuIn;
