import Base from './Base';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isFluid?: boolean;
}

const Container = styled(Base)<ContainerProps>`
  ${({ isFluid, theme }) => css`
    --container-max-width: ${theme.containerMaxWidth}px;
    --container-gutter-x: ${theme.containerGutterX}px;
    width: 100%;
    max-width: var(--container-max-width);
    padding-right: var(--container-gutter-x);
    padding-left: var(--container-gutter-x);
    margin-right: auto;
    margin-left: auto;

    @media (min-width: ${theme.breakpoints.desktop}px) {
      --container-gutter-x: ${theme.containerGutterX * 2}px;
    }

    /* 
     * Fluid
     */

    ${isFluid &&
    `
      --container-max-width: initial;
    `}
  `}
`;

export default Container;
