import { CSSProperties } from 'react';
import Base, { BaseProps } from './Base';
import styled, { css } from 'styled-components';

type Props = BaseProps & {
  span?: 'auto' | CSSProperties['width'] | boolean;
};

export type ColProps = Props & { mobile?: Props; desktop?: Props };

const styles = (props: Props) => css`
  flex: 1 1 0;
  max-width: 100%;
  min-width: 0; // text truncation fix

  /*
   * Auto
   */

  ${props.span === 'auto' &&
  `
    flex: 0 0 auto;
    width: auto;
  `}

  /*
   * Value
   */

  ${typeof props.span === 'string' &&
  props.span.length &&
  props.span !== 'auto' &&
  `
    flex: 0 0 ${props.span};
    max-width: ${props.span};
  `}
`;

const Col = styled(Base)<ColProps>`
  ${({ mobile = {}, desktop = {}, theme, ...props }) => css`
    ${styles(props)};

    /*
     * Mobile
     */

    ${mobile.span &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        ${styles(mobile)}
      }
    `}

    /*
     * Desktop
     */

    ${desktop.span &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        ${styles(desktop)}
      }
    `}
  `}
`;

export default Col;
