import React, { CSSProperties } from 'react';
import { CustomTheme } from '~/@types/styled-components';
import { darken, fadeIn } from '~/helpers';
import { BaseButton, BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { Spinner } from '~/components/ui/Spinner';
import styled, { css } from 'styled-components';

//
// Spinner
//

const StyledSpinner = styled(Spinner)`
  ${() => css`
    &::before {
      width: var(--btn-spinner-size) !important;
      height: var(--btn-spinner-size) !important;
      border-width: var(--btn-spinner-border-width) !important;
      color: var(--btn-spinner-color);
    }
  `}
`;

//
// Button
//

type StyledButtonProps = {
  float?: CSSProperties['float'];
  hasShadow?: boolean;
  isActive?: boolean;
  isBlock?: boolean;
  isFading?: boolean;
  isFlush?: boolean;
  isLink?: boolean;
  isLoading?: boolean;
  isSquare?: boolean;
  size?: keyof CustomTheme['buttonPaddingX'];
  variant?: keyof CustomTheme['colors'] | 'default';
};

export const StyledButton = styled(BaseButton)<StyledButtonProps>`
  ${({ isBlock, isFading, isFlush, isLink, isLoading, isSquare, hasShadow, size = 'base', variant = 'primary', float, theme }) => css`
    --btn-padding-y: ${theme.buttonPaddingY[size]}px;
    --btn-padding-x: ${theme.buttonPaddingX[size]}px;
    --btn-line-height: ${theme.buttonLineHeight}px;
    --btn-font-size: ${theme.buttonFontSize[size]}px;
    --btn-background-color: ${variant !== 'default' ? theme.colors[variant] : 'unset'};
    --btn-border-radius: ${theme.buttonBorderRadius[size]}px;
    --btn-border-width: ${theme.buttonBorderWidth}px;
    --btn-border-color: ${variant !== 'default' ? theme.colors[variant] : 'unset'};
    --btn-box-shadow: ${hasShadow ? theme.buttonBoxShadow : 'none'};
    --btn-color: ${theme.colors.white};
    --btn-hover-background-color: ${variant !== 'default' ? darken(theme.colors[variant], 0.1) : 'unset'};
    --btn-hover-border-color: ${variant !== 'default' ? darken(theme.colors[variant], 0.1) : 'unset'};
    --btn-hover-color: ${theme.colors.white};
    --btn-spinner-size: ${theme.buttonIconSize[size]}px;
    --btn-spinner-border-width: ${theme.buttonIconSize[size] / 6}px;
    --btn-spinner-color: ${theme.colors.white};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    line-height: var(--btn-line-height);
    font-size: var(--btn-font-size);
    vertical-align: middle;
    white-space: nowrap;
    background-color: var(--btn-background-color);
    border-radius: var(--btn-border-radius);
    border: var(--btn-border-width) solid var(--btn-border-color);
    outline: none !important;
    box-shadow: var(--btn-box-shadow);
    color: var(--btn-color);
    transition: ${theme.transition.base};
    transition-property: background-color, border-color, box-shadow, color;
    cursor: pointer;

    /* Hover */

    &:hover {
      background-color: var(--btn-hover-background-color);
      border-color: var(--btn-hover-border-color);
      color: var(--btn-hover-color);
    }

    /* Disabled */

    ${!isLoading &&
    `
      &:disabled {
        pointer-events: none;

        & > * {
          opacity: .5;
        }
      }
    `}

    /* Next */

    & + & {
      margin-left: ${theme.spacers[3]}px;
    }

    /* Button white */

    ${variant === 'white' &&
    `
      --btn-background-color: ${theme.buttonWhiteBg};
      --btn-border-color: ${theme.buttonWhiteBorderColor};
      --btn-color: ${theme.buttonWhiteColor};
      --btn-box-shadow: ${theme.buttonBoxShadow};
      --btn-spinner-color: ${theme.colors.primary};

      &:hover {
        --btn-hover-background-color: ${theme.buttonWhiteBg};
        --btn-hover-border-color: ${theme.buttonWhiteHoverBorderColor};
        --btn-hover-color: ${theme.buttonWhiteHoverColor};
      }
    `}

    /* Button transparent */

    ${variant === 'transparent' &&
    `
      --btn-background-color: ${theme.colors.white10};
      --btn-border-color: ${theme.colors.transparent};
      --btn-color: ${theme.colors.white};
      --btn-spinner-color: ${theme.colors.white};

      &:hover, &:focus {
        --btn-hover-background-color: ${fadeIn(theme.colors.white, 0.15)};
        --btn-hover-border-color: ${theme.colors.transparent};
      }
    `}

    /* Button default */

    ${variant === 'default' &&
    `
      --btn-background-color: ${theme.colors.white};
      --btn-border-color: ${theme.colors.white};
      --btn-color: ${theme.colors.primary};
      --btn-spinner-color: ${theme.colors.primary};

      &:hover, &:focus {
        --btn-hover-background-color: ${theme.colors.white};
        --btn-hover-border-color: ${theme.colors.white};
        --btn-hover-color: ${darken(theme.colors.primary, 0.1)};
      }
    `}

    /* Button link */

    ${isLink &&
    `
      --btn-background-color: transparent;
      --btn-border-color: transparent;
      --btn-color: ${theme.colors.gray700};
      --btn-box-shadow: none;
      --btn-spinner-color: ${theme.colors.gray700};

      &:hover, &:focus {
        --btn-hover-background-color: transparent;
        --btn-hover-border-color: transparent;
        --btn-hover-color: ${theme.colors.primary};
      }
    `}

    /* Button block */

    ${isBlock &&
    `
      display: flex;
      width: 100%;
    `}

    /* Button loading */

    ${isLoading &&
    `
      --btn-color: transparent !important;

      &:hover {
        --btn-hover-color: transparent !important;
      }
    `}

    /* Button fading */

    ${isFading &&
    `
      opacity: 0;

      // Prevents button from flashing when dragging its parent tile
      *:not([style*="transform"]) > *:hover > & {
        opacity: 1;
      }
    `}

    /* Button float */

    ${float &&
    `
      position: absolute;
      bottom: ${theme.spacers[6]}px;
    `}

    ${float === 'right' &&
    `
      right: ${theme.spacers[6]}px;
    `}

    ${float === 'left' &&
    `
      left: ${theme.spacers[6]}px;
    `}

    /* Button flush */

    ${isFlush &&
    `
      --btn-padding-y: 0;
      --btn-padding-x: 0;
      --btn-border-width: 0;
      --btn-box-shadow: none;
    `}

    /* Button square */

    ${isSquare &&
    `
      --btn-padding-x: ${theme.buttonPaddingY[size]}px;
      height: ${theme.buttonLineHeight + theme.buttonPaddingY[size] * 2 + theme.buttonBorderWidth * 2}px;
      width: ${theme.buttonLineHeight + theme.buttonPaddingY[size] * 2 + theme.buttonBorderWidth * 2}px;
    `}
  `}
`;

type ButtonType = {
  <C extends React.ElementType = 'button'>(props: ButtonProps<C> & { ref?: React.Ref<HTMLElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type ButtonInnerProps = StyledButtonProps;

export type ButtonProps<C extends React.ElementType = 'button'> = BaseHOCPropsWithoutRef<C, ButtonInnerProps>;

const Button: ButtonType = React.forwardRef(
  <C extends React.ElementType = 'button'>({ as, size, variant, children, ...props }: ButtonProps<C>, ref: React.Ref<HTMLElement>) => (
    <StyledButton ref={ref} as={as as React.ElementType} size={size} variant={variant} {...props}>
      {children}
      {props.isLoading && <StyledSpinner />}
    </StyledButton>
  ),
);

Button.displayName = 'Button';
export default Button;
