import { CSSProperties } from 'react';
import { CustomTheme } from '~/@types/styled-components';
import Base, { BaseProps } from './Base';
import styled, { css } from 'styled-components';

type Props = BaseProps & {
  columns?: CSSProperties['columns'];
  columnGap?: keyof CustomTheme['spacers'];
};

type ColumnsProps = Props & {
  mobile?: Props;
  desktop?: Props;
};

const Columns = styled(Base)<ColumnsProps>`
  ${({ columns = 1, columnGap = 0, mobile = {}, desktop = {}, theme }) => css`
    --column-count: ${columns};
    --column-gap: ${theme.spacers[columnGap]}px;
    columns: var(--column-count);
    column-gap: var(--column-gap);

    /*
     * Mobile
     */

    ${(mobile.columns || mobile.columnGap) &&
    css`
      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        --column-count: ${mobile.columns};
        --column-gap: ${mobile.columnGap};
      }
    `}

    /*
     * Desktop
     */

    ${(desktop.columns || desktop.columnGap) &&
    css`
      @media (min-width: ${theme.breakpoints.desktop}px) {
        --column-count: ${desktop.columns};
        --column-gap: ${desktop.columnGap};
      }
    `}
  `}
`;

export default Columns;
