import { BaseMain } from './Base';
import styled, { css } from 'styled-components';

const Main = styled(BaseMain)`
  ${({ theme }) => css`
    --main-padding-y: ${theme.spacers[8]}px;
    padding-top: var(--main-padding-y);
    padding-bottom: var(--main-padding-y);
  `}
`;

export default Main;
