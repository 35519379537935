import { BaseProps } from '../layout/Base';
import styled, { css } from 'styled-components';
import Text from './Text';
import { useEffect, useRef, useState } from 'react';

type TextExpandableType = {
  maxLines?: number;
};

const TextExpandable = styled(Text)<TextExpandableType>`
  ${({ maxLines }) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;

type LineClampProps = BaseProps &
  TextExpandableType & {
    children: React.ReactNode;
  };

export default function LineClamp({ maxLines, children, ...props }: LineClampProps) {
  const expandableRef = useRef<HTMLDivElement>(null);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const lineCap = isExpandable ? (isExpanded ? -1 : maxLines) : maxLines;

  const checkExpandable = (el: HTMLDivElement) => {
    return el.scrollHeight > el.offsetHeight;
  };

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsExpanded((boolean) => !boolean);
  };

  useEffect(() => {
    const expandableEl = expandableRef.current;
    if (expandableEl) {
      let lastWidth = expandableEl.offsetWidth;
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newWidth = entry.contentRect.width;
          if (newWidth !== lastWidth) {
            setIsExpandable(checkExpandable(expandableEl));
            lastWidth = newWidth;
          }
        }
      });
      resizeObserver.observe(expandableEl);
      return () => resizeObserver.unobserve(expandableEl!);
    }
  }, []);

  return (
    <Text {...props}>
      <TextExpandable ref={expandableRef} maxLines={lineCap}>
        {children}
      </TextExpandable>
      {isExpandable && (
        <Text as="span" utils={{ color: 'primary' }} onClick={toggleExpand} role="button">
          Read {isExpanded ? 'less' : 'more'}
        </Text>
      )}
    </Text>
  );
}
