import { useState, useEffect, useCallback, RefObject } from 'react';

interface Size {
  width: number;
  height: number;
}

function useElementSize<T extends HTMLElement>(element?: RefObject<T> | string): Size {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const updateSize = useCallback(() => {
    const target = element ? (typeof element === 'string' ? document.getElementById(element) : element.current) : undefined;

    if (target) {
      setSize({
        width: target.offsetWidth,
        height: target.offsetHeight,
      });
    } else {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }, [element]);

  useEffect(() => {
    const target = element ? (typeof element === 'string' ? document.getElementById(element) : element.current) : undefined;
    updateSize();

    let observer: ResizeObserver | undefined;
    if (target) {
      observer = new ResizeObserver(() => updateSize());
      observer.observe(target);
    } else {
      window.addEventListener('resize', updateSize);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      } else {
        window.removeEventListener('resize', updateSize);
      }
    };
  }, [element, updateSize]);

  return size;
}

export default useElementSize;
