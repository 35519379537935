import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const TextFiller = styled(Base)`
  ${({ theme }) => css`
    display: inline-block;
    height: 10px;
    background-color: ${theme.colors.gray300};
    border-radius: ${theme.borderRadius['sm']}px;
  `}
`;

export default TextFiller;
