import { keyframes } from 'styled-components';

const progressX = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

export default progressX;
